<template>
  <div style="display: flex; align-items: center; height: 100%">
    <div style="flex: 1">
      <div style="display: flex; align-items: center; text-align: center; height: 60px">
        <div style="flex: 1">
          <div style="font-size: 24px; font-weight: bold">{{ item.unfinished_picking_quantity }}</div>
          <div style="font-size: 14px">{{ $t("待拣货单数") }}</div>
        </div>
        <div style="flex: 1">
          <div style="font-size: 24px; font-weight: bold">{{ item.finished_picking_quantity }}</div>
          <div style="font-size: 14px">{{ $t("已拣货单数") }}</div>
        </div>
        <div style="flex: 1">
          <div style="font-size: 24px; font-weight: bold">{{ item.picking_operator_items.length }}</div>
          <div style="font-size: 14px">{{ $t("作业人数") }}</div>
        </div>
      </div>

      <div style="margin-top: 12px; height: 120px; overflow: auto">
        <table style="width: 100%; text-align: center">
          <thead style="background: rgba(0, 0, 0, 0.1)">
            <tr>
              <th>排名</th>
              <th>操作员</th>
              <th>处理单数</th>
              <th>总件数</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(picking_operator_item, index) in item.picking_operator_items.slice(0, 3)" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ picking_operator_item.operator_name }}</td>
              <td>{{ picking_operator_item.order_count }}</td>
              <td>{{ picking_operator_item.total_quantity }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: ["item"],
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
table {
  width: 100%;
  text-align: center;
}

table th {
  color: #fff;
  background: rgba(0, 0, 0, 0.1);
}

table td {
  color: rgba(255, 255, 255, 0.6);
}

table th,
table td {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 4px;
}
</style>
